import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

import styles from './Popup.module.scss'
import page1 from '../../assets/Popup/page1.jpg'
import page2 from '../../assets/Popup/page2.jpg'
import page3 from '../../assets/Popup/page3.jpg'

const popupArray = [
  { img: page1, url: "" },
  { img: page2, url: "" },
  { img: page3, url: "" },
]

const Popup = () => {
  const [isClick, setIsClick] = useState(false);

  return (
    <div className={styles.container}>
      {isClick &&
        <div className={styles.imgContainer} >
          {popupArray.map((value, idx) => (
            // <a key={idx} href={value.url} target="_blank" rel="noopener noreferrer">
              <img
                className={styles.popupImg}
                src={value.img}
                alt={`progio-popup-image-${idx}`}
              />
            // </a>
          ))}
        </div>
      }
      <div className={styles.openPopupBtn} onClick={() => setIsClick(!isClick)}>
        <div className={styles.btnIcon}>
          {isClick ? <FaAngleLeft size={20} color={"#FFFFFF"} /> : <FaAngleRight size={20} color={"#FFFFFF"} />}
        </div>
        <div className={styles.btnText}>
          POPUP
        </div>
      </div>
    </div>
  )
}

export default Popup;
