import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from './SalesInfo.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import Ready from "../../components/Ready/Ready";
import FixIcon from "../../components/FixIcon/FixIcon";
import HelmetCOM from "../../components/HelmetCOM/HelmetCOM";
import { Helmet } from "react-helmet-async";

const ComplexGuide3 = () => {
	const menuContents = [
		{ title: "인터넷 청약", url: "/SalesInfo/guide" },
		{ title: "체크포인트", url: "/SalesInfo/SubscriptionGuide" },
		{ title: "모집공고안내", url: "/SalesInfo/announcement" },
		{ title: "인지세납부안내", url: "/SalesInfo/stampTax" },
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	// 화면 스크롤이 탑이 아니면 isScroll 값 true로 변환
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>
			
			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="인지세납부안내" />

			<MenuBar contents={menuContents} />

			<div className={styles.textBox}>
				<div>브레인시티 푸르지오의 눈부신 가치 위에</div>
				<div>푸르지오의 새로운 자부심으로 찾아옵니다.</div>
			</div>

			<Ready />
		
			<Footer />
		</div>
	)
}

export default ComplexGuide3;
